// IMPORTS
@import (reference) "../settings.less";

body[data-type="mainpage"] main {
    margin-top: var(--rowGap);
}

// MAINPAGE SLIDERS
.slider-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    align-items: flex-start;
    text-align: center;

    .swiper-button-prev,
    .swiper-button-next {
        position: static;
        margin-top: 0;
    }
}

// PROMO BANNER
.promo-banners-wrapper {
    .grid();
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    gap: 32px;
    margin-top: -40px;
}

.promo-banner {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
    border: 1px solid @border-color;
    padding: 20px;
    position: relative;

    &:before,
    &:after {
        content: '';
        width: 48px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        background: @main-color;
    }

    &:before {
        top: -1px;
    }

    &:after {
        bottom: -1px;
    }

    figure {
        margin-bottom: 10px;
        width: 48px;
        aspect-ratio: 1;

        img {
            .img-contain();
        }
    }

    b {
        font-weight: 500;
        text-transform: uppercase;
        line-height: 1.2;
        letter-spacing: 0.2em;
        text-align: center;
    }

    p {
        text-align: center;
    }
}

// MAINPAGE PRODUCTS
.mainpage-products-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:nth-child(even of .mainpage-products-wrapper) {

        .mainpage-products-list {
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 2fr);

            .product:first-child {
                grid-column: 3 / 4;
                grid-row: 1 / 3;
            }
        }
    }
}

.mainpage-products-list {
    .grid();
    gap: var(--prodGap) 41px;
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr);

    .product {

        &:only-child {
            grid-column: 1 / -1 !important;
        }

        &:after {
            display: none;
        }

        .product-price:after {
            position: absolute;
            bottom: -32px;
            content: '';
            width: 100%;
            height: 1px;
            background: @border-color;
        }

        &:nth-child(4),
        &:nth-child(5) {

            .product-price:after {
                display: none;
            }
        }
    }
}

.mainpage-products-list .product:first-child { // big product
    grid-row: span 2;
    gap: 0;
    grid-template-rows: auto auto 1fr;
    align-items: start;
    align-self: center;
    max-width: 696px;

    .product-image {
        width: 100%;
        aspect-ratio: ~'686/718';
        margin-bottom: 24px;

        img {

            &:first-of-type {
                opacity: 1 !important;
            }

            &:nth-of-type(2) {
                display: none !important;
                animation: none !important;
            }
        }
    }

    .product-name {
        font-size: 24px;
        margin-bottom: 12px;
    }

    .product-price {
        font-size: 20px;
    }

    &:before {
        bottom: -37px;
    }
}